import { twMerge } from "tailwind-merge";
import { InputError } from "./InputError";
import { Label } from "components/common/v2/Label";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions
} from "@headlessui/react";
import { CaretDown } from "phosphor-react";

export type DropdownOption<T extends string | number> = { id: T; name: string };

export interface DropdownProps<T extends string | number> {
    id?: string;
    options: DropdownOption<T>[];
    value?: T;
    onChange: (value: T | undefined) => void;
    label?: string;
    placeholder?: string;
    error?: string;
    className?: string;
    required?: boolean;
    containerClassName?: string;
    disabled?: boolean;
    "data-testid"?: string;
}

export function Dropdown<T extends string | number>({
    id,
    options,
    value,
    onChange,
    label,
    placeholder = "Select",
    error,
    className = "",
    required,
    containerClassName,
    disabled,
    "data-testid": testId
}: DropdownProps<T>) {
    const selection = options.find(option => option.id === value);
    return (
        <div className={containerClassName}>
            <Label
                htmlFor={id}
                className={id && "cursor-pointer select-none"}
                required={required}
            >
                {label}
            </Label>
            <Listbox value={value} onChange={onChange} disabled={disabled}>
                {({ open }) => (
                    <>
                        <ListboxButton
                            id={id}
                            data-testid={testId}
                            className={twMerge(
                                "dropdown-button",
                                error && "border-error",
                                !selection?.name && "text-tertiaryOld",
                                className
                            )}
                        >
                            {selection?.name || placeholder || (
                                // Blank placeholder matching line height
                                <div className="min-h-6" />
                            )}
                            <CaretDown
                                size={15}
                                className={twMerge(
                                    "-mt-[2px] ml-1 transition-all fill-secondaryDark",
                                    open && "rotate-180"
                                )}
                            />
                        </ListboxButton>

                        <ListboxOptions
                            anchor="bottom"
                            className={twMerge(
                                "mt-1 !max-h-60 z-50 overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                                "w-[var(--button-width)]"
                            )}
                        >
                            {options.map(item => (
                                <ListboxOption
                                    key={item.id}
                                    data-testid={`option-${item.id}`}
                                    value={item.id}
                                    className={twMerge(
                                        "py-2 px-4 text-medium font-bold cursor-pointer rounded-[3px] ui-active:bg-lightGreenBg ui-active:text-main",
                                        item.name
                                            ? "text-secondaryDark"
                                            : "text-tertiaryOld"
                                    )}
                                >
                                    {item.name || placeholder}
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </>
                )}
            </Listbox>
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
}
