import React from "react";
import { SimpleModal } from "./SimpleModal";
import { AddClientForm } from "components/form/forms/AddClientForm";

export const AddClientModal = () => {
    return (
        <SimpleModal title="Add Client">
            {({ closeModal }) => <AddClientForm onDone={closeModal} />}
        </SimpleModal>
    );
};
