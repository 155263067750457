import React, { useState } from "react";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOptions,
    _internal_ComponentComboboxOption
} from "@headlessui/react";
import { CaretDown } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import { InputError } from "./InputError";
import { Label } from "components/common/v2/Label";

type OptionProps = React.ComponentProps<_internal_ComponentComboboxOption>;
const Option = ({
    id,
    value,
    children,
    disabled,
    className = "",
    ...otherOptions
}: OptionProps) => {
    return (
        <Combobox.Option
            id={id ? `prefix-option-${id}` : undefined}
            value={value}
            className={twMerge(
                `py-2 px-4 text-medium font-bold cursor-pointer rounded-[3px] ${
                    disabled
                        ? ""
                        : "text-secondaryDark ui-active:bg-lightGreenBg ui-active:text-main"
                } ${className}`
            )}
            disabled={disabled}
            {...otherOptions}
        >
            {children}
        </Combobox.Option>
    );
};

export type TypedownProps<T extends string | number> = {
    id?: string;
    options: {
        id: T;
        name: string;
    }[];
    value?: T;
    onChange: (value: T | undefined) => void;
    label?: string;
    placeholder?: string;
    className?: string;
    error?: string;
    "data-testid"?: string;
    required?: boolean;
    defaultSearchValue?: T;
    containerClassName?: string;
};

export function Typedown<T extends string | number>({
    id,
    options,
    value,
    onChange,
    label,
    placeholder,
    className,
    error,
    required,
    defaultSearchValue,
    containerClassName,
    ...otherProps
}: TypedownProps<T>) {
    const findById = (value?: T) => {
        if (value === undefined) return undefined;
        return options?.find(({ id }) => `${id}` === `${value}`);
    };
    const currentOption = findById(value);
    const currentDefault = findById(defaultSearchValue);

    const [typedownInput, setTypedownInput] = useState<string>("");
    const filteredOptions = options.filter(option =>
        option?.name?.toLowerCase()?.includes(typedownInput?.toLowerCase())
    );

    return (
        <div className={containerClassName}>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            <Combobox
                id={id}
                as="div"
                // immediate // TODO: Uncomment once headless UI is safely upgraded
                value={currentOption}
                onChange={option => onChange(option?.id ?? undefined)}
            >
                {({ open }) => (
                    <div className="relative">
                        <ComboboxInput
                            {...otherProps}
                            className={twMerge(
                                "w-full text-medium text-left rounded-xl hover:bg-transparent border-lightGreyBorder focus:border-lightGreyBorder focus:ring-0 dropdown-button px-4 py-3 placeholder:text-tertiaryOld",
                                error && "border-error",
                                className
                            )}
                            onChange={event =>
                                setTypedownInput(event.target.value)
                            }
                            displayValue={(option: { id: T; name: string }) =>
                                option?.name
                            }
                            placeholder={placeholder}
                            data-testid={
                                otherProps?.["data-testid"] ||
                                currentOption?.name ||
                                "typedown"
                            }
                            autoComplete="off"
                        />
                        <ComboboxButton
                            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-4 focus:outline-none"
                            data-testid={
                                otherProps?.["data-testid"]
                                    ? `${otherProps?.["data-testid"]}-caret`
                                    : ""
                            }
                        >
                            <CaretDown
                                size={15}
                                className={twMerge(
                                    "-mt-[2px] ml-1 transition-all fill-secondaryDark",
                                    open && "rotate-180"
                                )}
                            />
                        </ComboboxButton>
                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredOptions.length > 0 ? (
                                <>
                                    {filteredOptions.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option}
                                            id={option.id}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </>
                            ) : currentDefault ? (
                                <Option
                                    key={currentDefault.id}
                                    id={currentDefault.id}
                                    value={currentDefault}
                                >
                                    {currentDefault.name}
                                </Option>
                            ) : (
                                <Option
                                    value={null}
                                    className="text-normal text-center text-gray-400"
                                    disabled
                                >
                                    No options
                                </Option>
                            )}
                        </ComboboxOptions>
                    </div>
                )}
            </Combobox>
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
}
