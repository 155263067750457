import { AnalyticsWindow } from "./globals"
declare let window: AnalyticsWindow

const PortalAnalytics = {
  page(title: string, payload?: Object){
      window.analytics && window.analytics.page(title, payload);
  },

  identify(user_id: string, payload?: Object){
    window.analytics && window.analytics.identify(user_id.toString(), payload)
  },

 track(eventName: string, payload?: Object){
    window.analytics && window.analytics.track(eventName, payload)
  }
}

export default PortalAnalytics;