import { useFormikContext } from "formik";
import { FormProperties, PageSchema } from "types/form/schema";

interface Props {
    schema: PageSchema;
    properties: FormProperties;
}
export const SubmitScreen = ({ schema, properties }: Props) => {

    const { values } = useFormikContext<{
        [key: string]: number | string;
    }>();
    let descriptionWithDynamicValues =schema?.description;
    if(descriptionWithDynamicValues) {
    for (const property in values) {
        const splittedValue: string[] = descriptionWithDynamicValues?.split(`{${property}}`);
   
        if(splittedValue.length>1) {
            const propertyId =values[property];
            const propertyValue =  properties?.[property].oneOf ? properties?.[property].oneOf?.find(({id}: {id: string|number}) => `${id}`===`${propertyId}`)?.name : `${propertyId}`;
            descriptionWithDynamicValues = splittedValue.join(propertyValue);
        } else {
            descriptionWithDynamicValues = splittedValue.join('');
        }
      }
    }
    return (
        <div
            className="form-help"
            dangerouslySetInnerHTML={{ __html: descriptionWithDynamicValues || "" }}
        />
    );
};
