import PortalAnalytics from "PortalAnalytics";
import { URL_TRAININGS_LESSON_V2 } from "constants/urls";
import { useAuth } from "hooks/use-auth";
import { LessonI, ModuleI } from "types/training";
import { postData } from "utils/http-requests";

const MARK_COMPLETED_ERROR =
    "There was an error completing the lesson. Please, try again later";
export const useMarkLessonCompleted = ({
    track,
    module
}: {
    track: { slug: string };
    module: { slug: string } | ModuleI;
}) => {
    const { token } = useAuth();
    const markLessonAsComplete = async (
        lesson?: LessonI
    ): Promise<{ error?: string; success?: boolean }> => {
        if (!lesson)
            return {
                error: MARK_COMPLETED_ERROR
            };
        if (lesson.is_completed)
            return {
                success: true
            };
        try {
            const response = await postData(
                `${URL_TRAININGS_LESSON_V2}${lesson.slug}/complete/`,
                {},
                token
            );
            //TODO: Improve error returns in the backend services
            if (response?.detail) {
                return {
                    error: MARK_COMPLETED_ERROR
                };
            }
            if (lesson) {
                PortalAnalytics.track("training__lesson_marked_complete", {
                    track_slug: track.slug,
                    module_slug: module.slug,
                    lesson_slug: lesson.slug,
                    lesson_internal_name: lesson.internal_name,
                    lesson_name: lesson.name,
                    lesson_type: lesson.lesson_type,
                    time_to_complete: lesson.complete_time,
                    is_completed: lesson.is_completed
                });
            }
            return { success: true };
        } catch (e) {
            return {
                error: MARK_COMPLETED_ERROR
            };
        }
    };
    const onAnalyticsLessonCTAAction = (lesson: LessonI, source: string) => {
        PortalAnalytics.track("training__lesson_cta_action", {
            track_slug: track.slug,
            module_slug: module.slug,
            lesson_slug: lesson.slug,
            source_name: source,
            lesson_internal_name: lesson.internal_name,
            lesson_name: lesson.name,
            lesson_type: lesson.lesson_type,
            time_to_complete: lesson.complete_time,
            is_completed: lesson.is_completed
        });
    };
    return {
        localModule: module,
        markLessonAsComplete,
        onAnalyticsLessonCTAAction
    };
};
