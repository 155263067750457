import { CheckCircle, WarningCircle } from "phosphor-react";

export const EndScreen = ({
    title,
    description,
    status,
    onDone
}: {
    title?: string;
    description?: string;
    status?: string;
    onDone?: () => void;
}) => {
    return (
        <div className="mt-4">
            <div className="flex  mb-2">
                <div className="mb-2">
                    {status === "error" ? (
                        <div className="bg-error-bg p-3 rounded-full">
                            <WarningCircle
                                className="text-error"
                                size={14}
                                aria-hidden="true"
                                data-testid="WarningCircle-icon"
                            />
                        </div>
                    ) : (
                        <CheckCircle
                            className="text-success"
                            size={44}
                            weight="fill"
                            aria-hidden="true"
                            data-testid="checkCircle-icon"
                        />
                    )}
                </div>
                <div className="pt-1 pl-2">
                    {title && (
                        <div className="font-bold text-2xl mb-2 ">{title}</div>
                    )}
                    {description && (
                        <div className="text-secondaryDark text-medium mb-6">
                            {description}
                        </div>
                    )}
                </div>
            </div>
            <div className="w-full text-right">
                <button className="btn-primary px-6 py-3" onClick={onDone}>
                    {status === "error" ? "Try again" : "Close"}
                </button>
            </div>
        </div>
    );
};
