import { datadogLogs } from "@datadog/browser-logs";
import classNames from "classnames";
import Dropdown from "components/common/v1/Dropdown";
import {
    URI_ISSUES,
    URI_ISSUES_REASONS,
    URI_ISSUES_CATEGORIES,
    URI_ISSUES_SEVERITIES
} from "constants/urls";
import { Formik } from "formik";
import { useAuth } from "hooks/use-auth";
import { Spinner, Warning } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { getData, postDataWithStatus } from "utils/http-requests";
import * as Yup from "yup";
import { DateTime } from "luxon";
import {
    trackReportIssueCategoryClicked,
    trackReportIssueTypeClicked,
    trackReportIssueSeverityClicked,
    trackReportIssueCancelClicked,
    trackReportIssueSubmitClicked
} from "analytics/issues/report-issue-analytics";
import {
    IIssueCategory,
    IIssueType,
    IIssueSeverity,
    ReportIssueMetadata
} from "lib/types/issues.types";
import { Modal } from "components/modal/modals/Modal";
import { useModalManager } from "hooks/use-modal-manager";
import { useSearchParams, usePathname } from "next/navigation";
import { getURL } from "utils/url";
import { EndScreen } from "components/form/pages/EndScreen";
import { InputError } from "components/common/v2/InputError";
import Loader from "components/common/Loader";

interface FormValues {
    selectedIssueCategory: number | null;
    selectedIssueType: number | null;
    selectedIssueSeverity: number | null;
    issueDescription: string;
}

const pathMappingsToCategory = {
    "/help": "booking",
    "/partners": "booking",
    "/bookings": "booking",
    "/training": "training",
    "/marketing": "marketing",
    "/s/resources": "marketing",
    "/payments": "payments"
};

const getInitialCategory = (path: string | null): string | null => {
    for (const key in pathMappingsToCategory) {
        if (path?.startsWith(key)) {
            return pathMappingsToCategory[
                key as keyof typeof pathMappingsToCategory
            ];
        }
    }
    return null;
};

const DEFAULT_SEVERITY = "normal";

// sort alphabetically but force other to be last
const customIssueCategorySort = (
    a: IIssueCategory,
    b: IIssueCategory
): number => {
    if (a.category === "other") return 1; // 'other' goes last
    if (b.category === "other") return -1; // 'other' goes last
    return a.display_text.localeCompare(b.display_text);
};

// sort alphabetically but force other to be last
const customIssueTypeSort = (a: IIssueType, b: IIssueType): number => {
    if (a.display_text.startsWith("Other")) return 1; // 'other' goes last
    if (b.display_text.startsWith("Other")) return -1; // 'other' goes last
    return a.display_text.localeCompare(b.display_text);
};

export const ReportIssueModal = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const { closeModal } = useModalManager();
    const { token, userProfile } = useAuth();
    const base_url = window.location.origin;

    const [issueCategoryList, setIssueCategoryList] = useState<
        IIssueCategory[]
    >([]);
    const [issueSeverityList, setIssueSeverityList] = useState<
        IIssueSeverity[]
    >([]);
    const [issueCategoryToIssueList, setCategoryToIssueList] = useState<{
        string: IIssueType[];
    } | null>(null);

    const defaultSeverityIndex = issueSeverityList.findIndex(
        (severity: IIssueSeverity) => severity.severity === DEFAULT_SEVERITY
    );
    const defaultCategory = getInitialCategory(pathname);
    const defaultCategoryIndex = issueCategoryList.findIndex(
        (category: IIssueCategory) => category.category === defaultCategory
    );
    const initialValues = {
        selectedIssueCategory:
            defaultCategoryIndex >= 0 ? defaultCategoryIndex : null,
        selectedIssueType: null,
        selectedIssueSeverity:
            defaultSeverityIndex >= 0 ? defaultSeverityIndex : null,
        issueDescription: ""
    };

    const [categoryDropdownOpen, setCategoryDropdownOpen] =
        useState<boolean>(false);
    const [reasonDropdownOpen, setReasonDropdownOpen] =
        useState<boolean>(false);
    const [severityDropdownOpen, setSeverityDropdownOpen] =
        useState<boolean>(false);

    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showFailure, setShowFailure] = useState<boolean>(false);
    const [isModalSubmitting, setIsModalSubmitting] = useState<boolean>(false);

    useEffect(() => {
        const getIssueTypeList = async () => {
            try {
                const response = await getData(URI_ISSUES_REASONS, token);
                // group issues by category
                // {category: IIssueType[]}
                const transformedObject = response.reasons.reduce(
                    (acc: any, issue: IIssueType) => {
                        const categoryName = issue.category;
                        if (!acc[categoryName]) {
                            acc[categoryName] = [];
                        }
                        acc[categoryName].push(issue);
                        return acc;
                    },
                    {} as Record<string, IIssueType[]>
                );

                // sort issue types alphabetically but force other as last
                for (const key in transformedObject) {
                    if (transformedObject.hasOwnProperty(key)) {
                        transformedObject[key].sort(customIssueTypeSort);
                    }
                }

                setCategoryToIssueList(transformedObject);
            } catch (e) {
                setShowFailure(true);
                datadogLogs.logger.error("Error getting issue reasons", {
                    error: e
                });
                console.error(e);
            }
        };

        const getIssueCategoryList = async () => {
            try {
                const response = await getData(URI_ISSUES_CATEGORIES, token);
                const sortedCategoriesList = response.categories.sort(
                    customIssueCategorySort
                );
                setIssueCategoryList(sortedCategoriesList);
            } catch (e) {
                setShowFailure(true);
                datadogLogs.logger.error("Error getting issue categories", {
                    error: e
                });
                console.error(e);
            }
        };

        const getIssueSeverityList = async () => {
            try {
                const response = await getData(URI_ISSUES_SEVERITIES, token);
                setIssueSeverityList(response.severities);
            } catch (e) {
                setShowFailure(true);
                datadogLogs.logger.error("Error getting issue severities", {
                    error: e
                });
                console.error(e);
            }
        };
        getIssueTypeList();
        getIssueCategoryList();
        getIssueSeverityList();
    }, [token]);

    const getListOfIssuesForCategory = (categoryIndex: number | null) => {
        if (categoryIndex == null) {
            return [];
        }
        const currentCategoryObj = issueCategoryList[categoryIndex];
        return (
            issueCategoryToIssueList?.[
                currentCategoryObj?.category as keyof typeof issueCategoryToIssueList
            ] || []
        );
    };

    const validationSchema = Yup.object().shape({
        selectedIssueCategory: Yup.number()
            .required("Topic is required")
            .typeError("Topic is required"),
        selectedIssueType: Yup.number()
            .required("Type is required")
            .typeError("Type is required"),
        selectedIssueSeverity: Yup.number()
            .required("Severity is required")
            .typeError("Severity is required"),
        issueDescription: Yup.string().required("Details are required")
    });

    function constructMetadata({
        selectedIssueCategory,
        selectedIssueType,
        selectedIssueSeverity,
        issueDescription
    }: FormValues): ReportIssueMetadata {
        const issueTypeList = getListOfIssuesForCategory(selectedIssueCategory);

        return {
            advisor_email: userProfile?.email,
            advisor_name: `${userProfile?.firstName} ${userProfile?.lastName}`,
            advisor_id: `${userProfile?.id}`,
            // issue metadata
            issue_category:
                selectedIssueCategory != null
                    ? issueCategoryList[selectedIssueCategory]?.category
                    : null,
            issue_category_display_name:
                selectedIssueCategory != null
                    ? issueCategoryList[selectedIssueCategory]?.display_text
                    : null,
            issue_type:
                selectedIssueType != null
                    ? issueTypeList[selectedIssueType]?.slug
                    : null,
            issue_type_display_name:
                selectedIssueType != null
                    ? issueTypeList[selectedIssueType]?.display_text
                    : null,
            issue_severity:
                selectedIssueSeverity != null
                    ? issueSeverityList[selectedIssueSeverity]?.severity
                    : null,
            issue_severity_display_name:
                selectedIssueSeverity != null
                    ? issueSeverityList[selectedIssueSeverity]?.display_text
                    : null,
            issue_description: issueDescription || null,
            dateTime: DateTime.now()
        };
    }

    async function reportIssue(values: FormValues) {
        const {
            selectedIssueCategory,
            selectedIssueType,
            selectedIssueSeverity,
            issueDescription
        } = values;

        if (
            selectedIssueCategory != null &&
            issueCategoryList &&
            selectedIssueType != null &&
            selectedIssueSeverity != null &&
            issueSeverityList
        ) {
            const issueTypeList = getListOfIssuesForCategory(
                selectedIssueCategory
            );
            setIsModalSubmitting(true);
            const fullMetadata = constructMetadata(values);

            trackReportIssueSubmitClicked(fullMetadata);

            const selectedIssueCategoryObj: IIssueCategory =
                issueCategoryList[selectedIssueCategory];
            const selectedIssueTypeObj: IIssueType =
                issueTypeList[selectedIssueType];
            const selectedIssueSeverityObj: IIssueSeverity =
                issueSeverityList[selectedIssueSeverity];
            try {
                const newSearchParams = new URLSearchParams(
                    searchParams.toString()
                );
                newSearchParams.delete("modal");
                const url = getURL(`${base_url}${pathname}`, newSearchParams);
                await postDataWithStatus(
                    `${URI_ISSUES}`,
                    {
                        reason_id: selectedIssueTypeObj.id,
                        comment: issueDescription,
                        url,
                        metadata: fullMetadata,
                        category: selectedIssueCategoryObj.id,
                        severity: selectedIssueSeverityObj.id
                    },
                    token
                ).then(({ response }) => {
                    if (response.status >= 200 && response.status < 300) {
                        setIsModalSubmitting(false);
                        setShowSuccess(true);
                    } else if (response.status >= 400) {
                        setIsModalSubmitting(false);
                        setShowFailure(true);
                        datadogLogs.logger.error(`Error submitting issue`, {
                            "Issue Category": selectedIssueCategoryObj,
                            "Issue Type": selectedIssueTypeObj,
                            "Issue Severity": selectedIssueSeverityObj,
                            "Issue Description": issueDescription,
                            "Advisor Email": userProfile.email,
                            "Advisor Name": `${userProfile.firstName} ${userProfile.lastName}`,
                            "Advisor Id": `${userProfile.id}`
                        });
                    }
                });
            } catch (e) {
                setIsModalSubmitting(false);
                setShowFailure(true);
                datadogLogs.logger.error(`Error submitting issue`, {
                    "Issue Category": selectedIssueCategoryObj,
                    "Issue Type": selectedIssueTypeObj,
                    "Issue Severity": selectedIssueSeverityObj,
                    "Issue Description": issueDescription,
                    "Advisor Email": userProfile.email,
                    "Advisor Name": `${userProfile.firstName} ${userProfile.lastName}`,
                    "Advisor Id": `${userProfile.id}`
                });
                console.error(e);
            }
        }
    }

    function handleSelectedIssueCategory(selectedIndex: number) {
        setCategoryDropdownOpen(false);
        const currentCategoryObj = issueCategoryList[selectedIndex];
        trackReportIssueCategoryClicked(currentCategoryObj);
    }

    function handleSelectedIssueType(selectedIndex: number) {
        setReasonDropdownOpen(false);
        const issueTypeList = getListOfIssuesForCategory(selectedIndex);
        const currentIssueTypeObj = issueTypeList[selectedIndex];
        trackReportIssueTypeClicked(currentIssueTypeObj);
    }

    function handleSelectedIssueSeverity(selectedIndex: number) {
        setSeverityDropdownOpen(false);
        const currentIssueSeverityObj = issueSeverityList[selectedIndex];
        trackReportIssueSeverityClicked(currentIssueSeverityObj);
    }

    function handleCancel(values: FormValues) {
        closeModal();
        const metadata = constructMetadata(values);
        trackReportIssueCancelClicked(metadata);
    }

    const isDoneLoading =
        issueCategoryList.length > 0 &&
        issueSeverityList.length > 0 &&
        issueCategoryToIssueList != null;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={values => reportIssue(values)}
        >
            {formik => {
                const {
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    setFieldTouched,
                    values
                } = formik;

                const {
                    selectedIssueCategory,
                    selectedIssueType,
                    selectedIssueSeverity,
                    issueDescription
                } = values;
                const issueTypeList = getListOfIssuesForCategory(
                    selectedIssueCategory
                );

                return (
                    <Modal
                        onClose={() => handleCancel(values)}
                        title="Report an Issue"
                    >
                        {!isDoneLoading && !showSuccess && !showFailure ? (
                            <div
                                data-testid="loader"
                                className="px-6 py-8 w-full"
                            >
                                <Loader />
                            </div>
                        ) : showSuccess ? (
                            <EndScreen
                                title="Thank you for reporting!"
                                description="Your feedback helps us improve our product"
                                onDone={closeModal}
                                status="success"
                            />
                        ) : (
                            <>
                                <p className="text-medium text-secondaryDark font-normal text-left">
                                    We&apos;re sorry to hear you&apos;re facing
                                    issues. Please let us know the details and
                                    we&apos;ll work to resolve it.
                                </p>
                                <div className="mb-6">
                                    <p className="text-medium text-secondaryDark md:mb-2">
                                        Issue topic*
                                    </p>
                                    <Dropdown
                                        label={
                                            selectedIssueCategory != null
                                                ? issueCategoryList[
                                                      selectedIssueCategory
                                                  ]?.display_text
                                                : "Select issue topic"
                                        }
                                        isShowing={categoryDropdownOpen}
                                        setIsShowing={isShowing => {
                                            if (!isShowing) {
                                                /*
                                                This will trigger the error state if
                                                the dropdown is opened and closed
                                                without selecting an issue type
                                                */
                                                setFieldTouched(
                                                    "selectedIssueCategory"
                                                );
                                            }
                                            setCategoryDropdownOpen(isShowing);
                                        }}
                                        menuPosClassName={"left-auto right-0 "}
                                        menuClassName="!py-2 !w-full !mt-1 !shadow-button !text-tertiaryOld  "
                                        classNamesContainer={"!w-full"}
                                        buttonClassName={
                                            touched.selectedIssueCategory &&
                                            errors.selectedIssueCategory
                                                ? "border-error"
                                                : ""
                                        }
                                        showFooter={false}
                                    >
                                        {issueCategoryList &&
                                            issueCategoryList.map(
                                                (
                                                    issueCategory: IIssueCategory,
                                                    index: number
                                                ) => (
                                                    <div
                                                        key={index}
                                                        id={`option-${index?.toString()}`}
                                                        className={classNames(
                                                            "block py-2 text-medium text-main px-4 mb-2 last:mb-0 cursor-pointer hover:bg-successLight hover:font-bold",
                                                            selectedIssueCategory &&
                                                                issueCategoryList[
                                                                    selectedIssueCategory
                                                                ]?.category ===
                                                                    issueCategory.category
                                                                ? "bg-successLight font-bold"
                                                                : "font-normal"
                                                        )}
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "selectedIssueCategory",
                                                                index
                                                            );
                                                            setFieldTouched(
                                                                "selectedIssueCategory",
                                                                false
                                                            );
                                                            handleSelectedIssueCategory(
                                                                index
                                                            );
                                                            // if category chosen has only one issue, set this issue as
                                                            // default
                                                            if (
                                                                getListOfIssuesForCategory(
                                                                    index
                                                                ).length == 1
                                                            ) {
                                                                setFieldValue(
                                                                    "selectedIssueType",
                                                                    0
                                                                );
                                                            } else {
                                                                setFieldValue(
                                                                    "selectedIssueType",
                                                                    null
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            issueCategory.display_text
                                                        }
                                                    </div>
                                                )
                                            )}
                                    </Dropdown>
                                    {errors.selectedIssueCategory &&
                                        touched.selectedIssueCategory && (
                                            <div className="flex items-center justify-start gap-2 text-error mt-2">
                                                <Warning size={24} />
                                                {errors.selectedIssueCategory}
                                            </div>
                                        )}
                                </div>
                                {issueTypeList &&
                                    issueTypeList.length > 1 &&
                                    selectedIssueCategory && (
                                        <div className="mb-6">
                                            <p className="text-medium text-secondaryDark md:mb-2">
                                                Issue type*
                                            </p>
                                            <Dropdown
                                                label={
                                                    selectedIssueType != null
                                                        ? issueTypeList[
                                                              selectedIssueType
                                                          ]?.display_text
                                                        : "Select issue type"
                                                }
                                                isShowing={reasonDropdownOpen}
                                                setIsShowing={isShowing => {
                                                    if (!isShowing) {
                                                        /*
                                                    This will trigger the error state if
                                                    the dropdown is opened and closed
                                                    without selecting an issue type
                                                    */
                                                        setFieldTouched(
                                                            "selectedIssueType"
                                                        );
                                                    }
                                                    setReasonDropdownOpen(
                                                        isShowing
                                                    );
                                                }}
                                                menuPosClassName={
                                                    "left-auto right-0 "
                                                }
                                                menuClassName="!py-2 !w-full !mt-1 !shadow-button !text-tertiaryOld  "
                                                classNamesContainer={"!w-full"}
                                                buttonClassName={
                                                    touched.selectedIssueType &&
                                                    errors.selectedIssueType
                                                        ? "border-error"
                                                        : ""
                                                }
                                                showFooter={false}
                                            >
                                                {issueTypeList &&
                                                    issueTypeList.map(
                                                        (
                                                            issueType: IIssueType,
                                                            index: number
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                id={`option-${index?.toString()}`}
                                                                className={classNames(
                                                                    "block py-2 text-medium text-main px-4 mb-2 last:mb-0 cursor-pointer hover:bg-successLight hover:font-bold",
                                                                    selectedIssueType &&
                                                                        issueTypeList[
                                                                            selectedIssueType
                                                                        ]
                                                                            ?.slug ===
                                                                            issueType.slug
                                                                        ? "bg-successLight font-bold"
                                                                        : "font-normal"
                                                                )}
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        "selectedIssueType",
                                                                        index
                                                                    );
                                                                    setFieldTouched(
                                                                        "selectedIssueType",
                                                                        false
                                                                    );
                                                                    handleSelectedIssueType(
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    issueType.display_text
                                                                }
                                                            </div>
                                                        )
                                                    )}
                                            </Dropdown>
                                            {errors.selectedIssueType &&
                                                touched.selectedIssueType && (
                                                    <div className="flex items-center justify-start gap-2 text-error mt-2">
                                                        <Warning size={24} />
                                                        {
                                                            errors.selectedIssueType
                                                        }
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                <div className="mb-6">
                                    <p className="text-medium text-secondaryDark md:mb-2">
                                        Issue severity*
                                    </p>
                                    <Dropdown
                                        label={
                                            selectedIssueSeverity != null
                                                ? issueSeverityList[
                                                      selectedIssueSeverity
                                                  ]?.display_text
                                                : "Select issue severity"
                                        }
                                        labelClassNames={"text-left"}
                                        isShowing={severityDropdownOpen}
                                        setIsShowing={isShowing => {
                                            if (!isShowing) {
                                                /*
                                                This will trigger the error state if
                                                the dropdown is opened and closed
                                                without selecting an issue type
                                                */
                                                setFieldTouched(
                                                    "selectedIssueSeverity"
                                                );
                                            }
                                            setSeverityDropdownOpen(isShowing);
                                        }}
                                        menuPosClassName={"left-auto right-0 "}
                                        menuClassName="!py-2 !w-full !mt-1 !shadow-button !text-tertiaryOld  "
                                        classNamesContainer={"!w-full"}
                                        buttonClassName={
                                            touched.selectedIssueSeverity &&
                                            errors.selectedIssueSeverity
                                                ? "border-error"
                                                : ""
                                        }
                                        showFooter={false}
                                    >
                                        {issueSeverityList &&
                                            issueSeverityList.map(
                                                (
                                                    issueSeverity: IIssueSeverity,
                                                    index: number
                                                ) => (
                                                    <div
                                                        key={index}
                                                        id={`option-${index?.toString()}`}
                                                        className={classNames(
                                                            "block py-2 text-medium text-main text-left px-4 mb-2 last:mb-0 cursor-pointer hover:bg-successLight hover:font-bold",
                                                            selectedIssueSeverity &&
                                                                issueSeverityList[
                                                                    selectedIssueSeverity
                                                                ]?.severity ===
                                                                    issueSeverity.severity
                                                                ? "bg-successLight font-bold"
                                                                : "font-normal"
                                                        )}
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "selectedIssueSeverity",
                                                                index
                                                            );
                                                            handleSelectedIssueSeverity(
                                                                index
                                                            );
                                                        }}
                                                    >
                                                        {
                                                            issueSeverity.display_text
                                                        }
                                                    </div>
                                                )
                                            )}
                                    </Dropdown>
                                    {errors.selectedIssueSeverity &&
                                        touched.selectedIssueSeverity && (
                                            <div className="flex items-center justify-start gap-2 text-error mt-2">
                                                <Warning size={24} />
                                                {errors.selectedIssueSeverity}
                                            </div>
                                        )}
                                </div>
                                <div className="mb-8">
                                    <p className="text-medium text-secondaryDark md:mb-2">
                                        Describe the issue*
                                    </p>
                                    <textarea
                                        className={`form-control text-medium py-[11px] min-h-[167px] resize-none 
                                        ${
                                            touched.issueDescription &&
                                            errors.issueDescription
                                                ? "border-error"
                                                : ""
                                        }`}
                                        placeholder={
                                            "Please provide specific details about the issue. The more details you provide, the better we can address the problem and improve the experience."
                                        }
                                        value={issueDescription}
                                        onChange={e => {
                                            setFieldValue(
                                                "issueDescription",
                                                e.target.value
                                            );
                                        }}
                                        onBlur={() => {
                                            setFieldTouched("issueDescription");
                                        }}
                                    />
                                    {errors.issueDescription &&
                                        touched.issueDescription && (
                                            <div className="flex items-center justify-start gap-2 text-error mt-2">
                                                <Warning size={24} />
                                                {errors.issueDescription}
                                            </div>
                                        )}
                                </div>
                                <div className="action-button-bar justify-between">
                                    <div>
                                        {showFailure && (
                                            <InputError className="mr-2">
                                                There was an unexpected error,
                                                please try again
                                            </InputError>
                                        )}
                                    </div>
                                    <div className="action-button-bar">
                                        <button
                                            className="btn-big justify-center"
                                            onClick={() => handleCancel(values)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn-primary btn-big"
                                            type="submit"
                                            disabled={isModalSubmitting}
                                            onClick={() => {
                                                setFieldTouched(
                                                    "selectedIssueType"
                                                );
                                                setFieldTouched(
                                                    "issueDescription"
                                                );
                                                handleSubmit();
                                            }}
                                        >
                                            Report
                                            {isSubmitting && (
                                                <Spinner className="animate-[spin_1.5s_ease-in-out_infinite] text-[24px] ml-1" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </Modal>
                );
            }}
        </Formik>
    );
};
