import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
import { DateTime } from "luxon";

import { User } from "pages/api/user";

export const INITIAL_USER: User = {
    isLoggedIn: false,
    login: "",
    roles: []
};
export const DEFAULT_PAGE_LIMIT = 20;
export const PAGE_LIMIT_50 = 50;
export const PAGE_LIMIT_100 = 100;

export const fieldIsRequiredMsg = "This field is required.";
export const validEmailMsg = "Enter a valid email address.";
export const validPastDateMsg = "Date in the past is not allowed.";
export const invalidImagelMsg =
    "Upload a valid image. The file you uploaded was either not an image or a corrupted image";
export const charsLength100 = 100;
export const DATE_FORMAT_YYYY_LL_DD = "yyyy-LL-dd";
export const DATE_FORMAT_MMM_D = "MMM d";
export const DATE_FORMAT_MMM_D_YYYY = "MMM. d, yyyy";
export const DATE_FORMAT_MMMM_D_YYYY = "MMMM d, yyyy";

export const DATE_FORMAT_CCCC_LLLL_D_YYYY = "cccc LLLL d, yyyy";
export const DATE_FORMAT_CCC_LLL_D_YYYY = "ccc LLL d, yyyy";
export const DATE_FORMAT_CCC_COMA_LLL_D_YYYY = "ccc, LLL d, yyyy";
export const TIME_FORMAT_H_MM_A_ZZZZ = "h:mm a ZZZZ";
export const DATE_TIME_FORMAT_CCC_COMA_LLL_D_YYYY_H_MM_A_ZZZZ =
    "ccc, LLL d, yyyy h:mm a ZZZZ";

export const DATE_FORMAT_LLLL_D_YYYY = "LLL d, yyyy";
/* short date format Month day (Aug 1) */
export const DATE_FORMAT_LLL_D = "LLL d";
export const DATE_FORMAT_WITHOUT_DOT_MMM_D_YYYY = "MMM d, yyyy";
export const DATE_FORMAT_WITHOUT_DOT_MMMM_D_YYYY = "MMMM d, yyyy";
export const DATE_TIME_FORMAT_MMMM_D_YYYY_H_MM_A = "MMMM d, yyyy 'at' h:mma";

export const DATE_FORMAT_SLASHED_MM_DD_YYYY = "MM/dd/yyyy";
export const DATE_FORMAT_SLASHED_M_D_YY = "M/d/yy";
export const getPreparedDateFormat = (date: Date) =>
    date.getMonth() === 4 ? "MMM d, yyyy" : "MMM. d, yyyy";
export const PASSWORD_REGEX =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()-_+])[0-9a-zA-Z!@#$%^&*()-_+]{8,}$/gi;
export const PHONE_NUMBER_REGEX = "[- ()\\d]*";
export const PHONE_LENGTH_REGEX = /(.*[0-9]){3}/i;
export const POSTAL_CODE_REGEX = "[0-9]*";
export const MIN_PASSWORD_LENGTH = 10;
export const MAX_PASSWORD_LENGTH = 100;
export const MAX_ZIP_CODE_LENGTH = 10;
export const MIN_KTN_LENGTH = 9;
export const MAX_KTN_LENGTH = 10;
export const legalAgreementText =
    "The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of www.foratravel.com, including any content, functionality, and services offered on or through foratravel.com, fora.travel, or any content or interaction on our social media channels (collectively, the “Website”), whether as a guest or a registered user. These Terms of Use are entered into by and between you and Greenwich and Warren, Inc. dba Fora (“Fora”, “we,” or “us”).‍ Please read the Terms of Use carefully before you start to use the Website. By using the Website, or clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by both these Terms of Use and our Privacy Policy, found at https://www.foratravel.com/legal/privacy-policy/, which is incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website. This Website is offered and available to users who are 13 years of age or older, and is governed by the laws of New York, United States, as set forth below under the heading “Governing Law and Jurisdiction.” Users who are under 18 years of age must have the consent of a parent or legal guardian to use this Website. By using the Website, or clicking to accept or agree to the Terms of Use, you represent and warrant that you meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.";

export const numberOfSlicedAmenities = 6;

export const defaultCountryFromAPI = {
    // TODO move to backend
    id: 23,
    text: "United States of America",
    code: "US"
};

export const notificationDelay = 5000;

export const maxLengthForInput = 1024;
export const maxShortLengthForInput = 255;
export const maxShortLengthForNickName = 200;
export const maxCharactersLength500 = 500;

export enum bookingsApiLimit {
    MOBILE = 100,
    MAIN = 25
}

export enum partnerProgramApiLimit {
    MAIN = 9,
    MOBILE = 6
}

export enum AuthProviders {
    GOOGLE = "google",
    CREDENTIALS = "simple-login",
    LOGIN_AS_ADVISOR = "login-as-advisor",
    FIRST_TIME_LOGIN = "first-time-login"
}

export const tomorrowDate = DateTime.now().plus({ days: 1 }).toJSDate();

export enum UiMode {
    edit,
    view
}

export enum BookingCategoriesEnum {
    HOTEL = "HOTEL",
    TRANSPORTATION = "TRANSPORTATION",
    DMC = "DMC",
    VILLA = "VILLA",
    CRUISE = "CRUISE",
    INSURANCE = "INSURANCE",
    TOUR = "TOUR",
    TICKET = "TICKET",
    FLIGHT = "FLIGHT",
    OTHER = "OTHER"
}

export enum BookingStatusesEnum {
    BOOKED = "Booked",
    CANCELED = "Canceled",
    COMPLETED = "Completed",
    TRAVELING = "Traveling"
}

export enum PaidStatusesEnum {
    PENDING = "pending",
    EXPECTED = "expected",
    PAID = "paid",
    NOT_PAYABLE = "not_payable",
    NON_COMMISSIONABLE = "non-commissionable"
}

export enum PaymentStatusesEnumNew {
    VALIDATION = "validation",
    NON_COMMISSIONABLE = "non-commission",
    PAID = "paid",
    CANCELED = "canceled"
}

export type BookingStatusesValuesType =
    | "booked"
    | "completed"
    | "traveling"
    | "cancelled";
export type BookingStatusesObjType = {
    name: BookingStatusesEnum;
    value: BookingStatusesValuesType;
};
export const BookingStatusesObj: BookingStatusesObjType[] = [
    { name: BookingStatusesEnum.BOOKED, value: "booked" },
    { name: BookingStatusesEnum.TRAVELING, value: "traveling" },
    { name: BookingStatusesEnum.COMPLETED, value: "completed" },
    { name: BookingStatusesEnum.CANCELED, value: "cancelled" }
];

export enum PaymentStatusesEnum {
    PENDING = "Payment pending",
    PAID = "Payment complete",
    NON_COMMISSIONABLE = "Non-commissionable",
    PENDING_PARTNER = "Awaiting partner payment",
    SCHEDULED = "Payment scheduled"
}

export type PaymentStatusesValuesType =
    | "complete"
    | "pending"
    | "non-commissionable"
    | "pending-partner"
    | "scheduled";
export type PaymentStatusesObjType = {
    name: PaymentStatusesEnum;
    value: PaymentStatusesValuesType;
};
export const PaymentStatusesObj: PaymentStatusesObjType[] = [
    { name: PaymentStatusesEnum.PENDING_PARTNER, value: "pending-partner" },
    { name: PaymentStatusesEnum.SCHEDULED, value: "scheduled" },
    { name: PaymentStatusesEnum.PAID, value: "complete" },
    {
        name: PaymentStatusesEnum.NON_COMMISSIONABLE,
        value: "non-commissionable"
    }
];
export type TypeDateFilterOption = {
    id: string;
    value: string;
    date?: {
        from: string;
        to: string;
    };
    slug?: string;
};
export const DateFilterOptions: TypeDateFilterOption[] = [
    {
        id: "today",
        value: "Today",
        date: {
            from: DateTime.now().toISODate(),
            to: DateTime.now().toISODate()
        }
    },
    {
        id: "thisWeek",
        value: "This week",
        date: {
            from: DateTime.now().startOf("week").toISODate(),
            to: DateTime.now().endOf("week").toISODate()
        },
        slug: "this_week"
    },
    {
        id: "nextWeek",
        value: "Next week",
        date: {
            from: DateTime.now().plus({ days: 7 }).startOf("week").toISODate(),
            to: DateTime.now().plus({ days: 7 }).endOf("week").toISODate()
        }
    },
    {
        id: "thisMonth",
        value: "This month",
        date: {
            from: DateTime.now().startOf("month").toISODate(),
            to: DateTime.now().endOf("month").toISODate()
        }
    },
    {
        id: "thisYear",
        value: "This year",
        date: {
            from: DateTime.now().startOf("year").toISODate(),
            to: DateTime.now().endOf("year").toISODate()
        }
    },
    { id: "custom", value: "Custom" }
];
export const DateFilterOptions_V2: TypeDateFilterOption[] = [
    { id: "custom", value: "Custom" },
    {
        id: "lastWeek",
        value: "Last week",
        date: {
            from: DateTime.now()
                .minus({ days: 7 })
                .startOf("week")
                .minus({ days: 1 })
                .toISODate(),
            to: DateTime.now()
                .minus({ days: 7 })
                .endOf("week")
                .minus({ days: 1 })
                .toISODate()
        },
        slug: "last_week"
    },
    {
        id: "lastMonth",
        value: "Last month",
        date: {
            from: DateTime.now()
                .minus({ month: 1 })
                .startOf("month")
                .toISODate(),
            to: DateTime.now().minus({ month: 1 }).endOf("month").toISODate()
        },
        slug: "last_month"
    },
    {
        id: "lastYear",
        value: "Last year",
        date: {
            from: DateTime.now().minus({ year: 1 }).startOf("year").toISODate(),
            to: DateTime.now().minus({ year: 1 }).endOf("year").toISODate()
        },
        slug: "last_year"
    },
    {
        id: "currentWeekToDate",
        value: "Current week to date",
        date: {
            from: DateTime.now().startOf("week").minus({ days: 1 }).toISODate(),
            to: DateTime.now().toISODate()
        },
        slug: "this_week"
    },
    {
        id: "currentMonthToDate",
        value: "Current month to date",
        date: {
            from: DateTime.now().startOf("month").toISODate(),
            to: DateTime.now().toISODate()
        },
        slug: "this_month"
    },
    {
        id: "currentYearToDate",
        value: "Current year to date",
        date: {
            from: DateTime.now().startOf("year").toISODate(),
            to: DateTime.now().toISODate()
        },
        slug: "ytd"
    }
];
export const DateFilterOptions2_V2: TypeDateFilterOption[] = [
    { id: "custom", value: "Custom" },
    {
        id: "allPast",
        value: "All past",
        date: {
            from: "",
            to: DateTime.now().minus({ days: 1 }).toISODate()
        }
    },
    {
        id: "allFuture",
        value: "All future",
        date: {
            from: DateTime.now().plus({ days: 1 }).toISODate(),
            to: ""
        },
        slug: "dashboardIsFutureBookings"
    },
    {
        id: "next10Days",
        value: "Next 10 days",
        date: {
            from: DateTime.now().toISODate(),
            to: DateTime.now().plus({ day: 9 }).toISODate()
        }
    },
    {
        id: "next30Days",
        value: "Next 30 days",
        date: {
            from: DateTime.now().toISODate(),
            to: DateTime.now().plus({ day: 29 }).toISODate()
        }
    },
    {
        id: "past10Days",
        value: "Past 10 days",
        date: {
            from: DateTime.now().minus({ day: 10 }).toISODate(),
            to: DateTime.now().minus({ days: 1 }).toISODate()
        }
    },
    {
        id: "past30Days",
        value: "Past 30 days",
        date: {
            from: DateTime.now().minus({ day: 30 }).toISODate(),
            to: DateTime.now().minus({ days: 1 }).toISODate()
        }
    }
];

export type TypeBookingSortingOptions = {
    id: number | string;
    field: string;
    desc: boolean;
    value: string;
    uriSlug?: string;
};

export const BookingSortingOptions: TypeBookingSortingOptions[] = [
    {
        id: 1,
        field: "created_at",
        value: "Booked date: most recent (Default)",
        uriSlug: "bd-most-recent",
        desc: false
    },
    {
        id: 2,
        field: "created_at",
        value: "Booked date: oldest",
        uriSlug: "bd-oldest",
        desc: true
    },
    {
        id: 3,
        field: "arrival",
        value: "Trip date: most recent",
        uriSlug: "td-most-recent",
        desc: false
    },
    {
        id: 4,
        field: "arrival",
        value: "Trip date: oldest",
        uriSlug: "td-oldest",
        desc: true
    },
    {
        id: 5,
        field: "commissions_payable",
        value: "Your commission: high to low",
        uriSlug: "cs-high-to-low",
        desc: false
    },
    {
        id: 6,
        field: "commissions_payable",
        value: "Your commission: low to high",
        uriSlug: "cs-low-to-high",
        desc: true
    },
    {
        id: 7,
        field: "est_commission",
        value: "Commission %: high to low",
        uriSlug: "c-high-to-low",
        desc: false
    },
    {
        id: 8,
        field: "est_commission",
        value: "Commission %: low to high",
        uriSlug: "c-low-to-high",
        desc: true
    }
];

export enum StatusFilter {
    BOOKING = "booking_status",
    COMMISSION = "commission_status"
}

export const COMPENSATION_TERMS_AGREE_LINK =
    process.env.NEXT_PUBLIC_COMPENSATION_TERMS_AGREE_LINK;

export const NEW_DASHBOARD_FEATURE = "new_dashboard";

export const mobileAndTabletScreen = 1024;

export const mobileScreen = 744;

export type CodePrefix = {
    code: string;
    name: string;
};

export const ClientNoteTeaserLength = 181; // value is got from design

export const BookingInstructionsTeaserLength = 250;

export const IATA = "33520476";
export const IATA_DASH = "33-520476";
export const FORA_PHONE_NUMBER = "+1 844-409-FORA (3672)";

export const EMAIL_VALIDATION =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const appPageTitleSuffix = "Fora Advisor Portal";
