import { getSession, signOut } from "next-auth/react";
import * as Sentry from "@sentry/nextjs";

import { URI_LOGIN, URL_BASE } from "constants/urls";

export async function getData(url = "", token = "") {
    let headers: any = {
        "Content-Type": "application/json"
    };
    if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` }; // Bearer or Token
    }
    const response = await fetch(url, {
        headers,
        referrerPolicy: "no-referrer" // no-referrer, *client
    });

    if (
        !response.ok &&
        response.status === 401 &&
        typeof window !== "undefined"
    ) {
        const validToken = await getValidTokenOrSignOut(token);
        if (validToken) {
            return getData(url, validToken);
        }
        return {};
    }

    return await response.json();
}

export async function getFullData(url = "", token = "", isJson = true) {
    let headers: any = {
        "Content-Type": "application/json"
        /*"Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, Accept"*/
    };
    if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` }; // Bearer or Token
    }
    const response = await fetch(url, {
        headers,
        referrerPolicy: "no-referrer" // no-referrer, *client
    });
    let data: any = {};

    if (
        !response.ok &&
        response.status === 401 &&
        typeof window !== "undefined"
    ) {
        const validToken = await getValidTokenOrSignOut(token);
        if (validToken) {
            return getFullData(url, validToken, isJson);
        }
        return {
            data: {},
            response: { ok: false, status: 401 } as Response
        };
    }
    if (isJson) {
        try {
            data = await response.clone().json();
        } catch (e: any) {
            console.error(response.statusText);
            if (response.status <= 500) {
                Sentry.captureException(e, {
                    contexts: {
                        response: {
                            statusText: response.statusText,
                            responseText: await response.text(),
                            fullResponse: response
                        }
                    }
                });
            }
        }
    }
    return { data, response };
}

export async function postData(url = "", data = {}, token = "") {
    let headers: any = {
        "Content-Type": "application/json"
    };
    if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
    }
    // Default options are marked with *
    const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    if (
        !response.ok &&
        response.status === 401 &&
        typeof window !== "undefined"
    ) {
        const validToken = await getValidTokenOrSignOut(token);
        if (validToken) {
            return postData(url, data, validToken);
        }
        return null;
    }
    const bodyContent = await response.text();

    return bodyContent.length ? JSON.parse(bodyContent) : null; // parses JSON response into native JavaScript objects, handled case if response has content-type='application/json', but body is empty
}

export const sendData = async ({
    url = "",
    data = {},
    token = "",
    method = "POST",
    contentType = "application/json"
}: any): Promise<{
    data: any;
    response: Response | undefined;
    errors: any;
}> => {
    let headers: any;
    if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    let body: any;

    if (contentType === "multipart/form-data") {
        let formData = new FormData();
        Object.entries(data).map((item: any) => {
            // TODO specify backend API data
            if (item[1] instanceof File) {
                formData.append(item[0], item[1], item[1]?.name);
            } else {
                formData.append(item[0], item[1]);
            }
        });

        body = formData;
    }

    if (contentType === "application/json") {
        body = JSON.stringify(data);
        headers = { ...headers, "Content-Type": contentType };
    }

    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body // body data type must match "Content-Type" header
        });
        if (response?.status >= 500) {
            throw response;
        }
        if (
            !response?.ok &&
            response?.status === 401 &&
            typeof window !== "undefined"
        ) {
            const validToken = await getValidTokenOrSignOut(token);
            if (validToken) {
                return sendData({ url, data, token: validToken, method, contentType });
            }
            return {
                data: {},
                response: {} as Response,
                errors: null
            };
        }
        let responseData: any = {};
        try {
            if (response.body) {
                responseData = await response?.json();
            }
        } catch (e) {
            if (response.status <= 500) {
                Sentry.captureException(e);
            }
        }
        let errors: { [key: string]: string[] } = !response?.ok
            ? responseData
            : null;

        return {
            data: responseData,
            response,
            errors
        }; // parses JSON response into native JavaScript objects
    } catch (e: any) {
        return {
            data: undefined,
            response: undefined,
            errors: e
        };
    }
};

export const sendDataWithout50xErrors = async ({
    url = "",
    data = {},
    token = "",
    method = "POST",
    contentType = "application/json"
}: any): Promise<{
    data: any;
    response: Response | undefined;
    errors: any;
}> => {
    let headers: any;
    if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    let body: any;

    if (contentType === "multipart/form-data") {
        let formData = new FormData();
        Object.entries(data).map((item: any) => {
            // TODO specify backend API data
            if (item[1] instanceof File) {
                formData.append(item[0], item[1], item[1]?.name);
            } else {
                formData.append(item[0], item[1]);
            }
        });

        body = formData;
    }

    if (contentType === "application/json") {
        body = JSON.stringify(data);
        headers = { ...headers, "Content-Type": contentType };
    }

    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body // body data type must match "Content-Type" header
        });
        if (
            !response?.ok &&
            response?.status === 401 &&
            typeof window !== "undefined"
        ) {
            const validToken = await getValidTokenOrSignOut(token);
            if (validToken) {
                return sendDataWithout50xErrors({ url, data, token: validToken, method, contentType });
            }
            return {
                data: {},
                response: {} as Response,
                errors: null
            };
        }
        let responseData: any = {};
        try {
            if (response.body) {
                responseData = await response?.json();
            }
        } catch (e) {
            console.error(e);
            if (response.status <= 500) {
                Sentry.captureException(e);
            }
        }
        let errors: { [key: string]: string[] } = !response?.ok
            ? responseData
            : null;

        return {
            data: responseData,
            response,
            errors
        }; // parses JSON response into native JavaScript objects
    } catch (e: any) {
        console.log(e);

        return {
            data: undefined,
            response: undefined,
            errors: undefined
        };
    }
};

/*Legacy function. Don't recommend use for a new functionality*/
export async function postDataWithStatus(url = "", data = {}, token = "") {
    let headers: any = {
        "Content-Type": "application/json"
    };
    if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        let errorData: any = {};
        if (
            !response.ok &&
            response.status === 401 &&
            typeof window !== "undefined"
        ) {
            const validToken = await getValidTokenOrSignOut(token);
            if (validToken) {
                return postDataWithStatus(url, data, validToken);
            }
            return {
                errorData,
                response: {} as Response
            };
        }
        try {
            if (response.status !== 404) {
                errorData = await response.json();
            }
        } catch (e) {
            console.log("Error on parsing a POST response body", url);
        }

        return {
            errorData,
            response
        };
    } catch (e) {
        console.log(e);

        return {
            response: {
                status: 500 /*Set 500 for EVERY 50x errors. To save backward compatibility this function. I see everywhere is expecting an exist status*/
            },
            errorData: "Internal Server Error"
        };
    }
}

export class HttpRequests {
    static async get({
        url = "",
        token = "",
        isJson = true,
        signal = null
    }: {
        url: string;
        token?: string;
        isJson?: boolean;
        signal?: AbortSignal | null;
    }): Promise<{
        data: any;
        response: Response;
    }> {
        let headers: any = {
            "Content-Type": "application/json"
        };
        if (token) {
            headers = { ...headers, Authorization: `Bearer ${token}` }; // Bearer or Token
        }
        const response = await fetch(url, {
            headers,
            referrerPolicy: "no-referrer", // no-referrer, *client
            signal
        });
        let data: any = {};

        if (
            !response.ok &&
            response.status === 401 &&
            typeof window !== "undefined"
        ) {
            const validToken = await getValidTokenOrSignOut(token);
            if (validToken) {
                return HttpRequests.get({
                    url,
                    token: validToken,
                    isJson,
                    signal
                });
            }
            
            return {
                data: {},
                response: {} as Response
            };
        }
        try {
            if (isJson) {
                try {
                    data = await response.clone().json();
                } catch (e: any) {
                    console.error(response.statusText);
                    if (response.status <= 500) {
                        Sentry.captureException(e, {
                            contexts: {
                                response: {
                                    statusText: response.statusText,
                                    responseText: await response.text(),
                                    fullResponse: response
                                }
                            }
                        });
                    }
                }
            }
        } catch (e) {
            console.error(e);
            if (response.status <= 500) {
                Sentry.captureException(e, {
                    contexts: {
                        response: {
                            statusText: response.statusText,
                            responseText: await response.text(),
                            fullResponse: response
                        }
                    }
                });
            }
        }

        return { data, response };
    }

    static set = async ({
        url = "",
        data = {},
        token = "",
        method = "POST",
        contentType = "application/json"
    }: any): Promise<{
        data: any;
        response: Response | undefined;
        errors: any;
    }> => {
        let headers: any;
        if (token) {
            headers = { ...headers, Authorization: `Bearer ${token}` };
        }

        let body: any;

        if (contentType === "multipart/form-data") {
            let formData = new FormData();
            Object.entries(data).map((item: any) => {
                // TODO specify backend API data
                if (item[1] instanceof File) {
                    formData.append(item[0], item[1], item[1]?.name);
                } else {
                    formData.append(item[0], item[1]);
                }
            });

            body = formData;
        }

        if (contentType === "application/json") {
            body = JSON.stringify(data);
            headers = { ...headers, "Content-Type": contentType };
        }

        // Default options are marked with *
        try {
            const response = await fetch(url, {
                method, // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers,
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *client
                body // body data type must match "Content-Type" header
            });
            if (response?.status >= 500) {
                throw response;
            }
            if (
                !response?.ok &&
                response?.status === 401 &&
                typeof window !== "undefined"
            ) {
                const validToken = await getValidTokenOrSignOut(token);
                if (validToken) {
                    return HttpRequests.set({
                        url,
                        data,
                        token: validToken,
                        method,
                        contentType
                    });
                }
                return {
                    data: {},
                    response: {} as Response,
                    errors: null
                };
            }
            let responseData: any = {};
            try {
                if (response.body) {
                    responseData = await response?.json();
                }
            } catch (e) {
                if (response.status <= 500) {
                    Sentry.captureException(e);
                }
            }
            let errors: { [key: string]: string[] } = !response?.ok
                ? responseData
                : null;

            return {
                data: responseData,
                response,
                errors
            }; // parses JSON response into native JavaScript objects
        } catch (e: any) {
            return {
                data: undefined,
                response: undefined,
                errors: e
            };
        }
    };

    static post = async ({ url = "", data = {}, token = "" }: any) =>
        await HttpRequests.set({
            url,
            data,
            token
        });
    static put = async ({ url = "", data = {}, token = "" }: any) =>
        await HttpRequests.set({
            url,
            data,
            token,
            method: "PUT"
        });
    static patch = async ({ url = "", data = {}, token = "" }: any) =>
        await HttpRequests.set({
            url,
            data,
            token,
            method: "PATCH"
        });
    static delete = async ({ url = "", data = {}, token = "" }: any) =>
        await HttpRequests.set({
            url,
            data,
            token,
            method: "DELETE"
        });
}

const getValidTokenOrSignOut = async (token: string) => {
    if (token && token.length !== 40) {
        const session = await getSession();
        if (session?.accessToken) {
            return session.accessToken;
        }
    }
    await signOut({
        callbackUrl: URI_LOGIN
    });
    return null;
}

// Utility function that can be passed to a .then to throw error if response is not ok or missing (so errors can be handled by a .catch)
export const throwIfNotOk = <T extends { response: any }>(input: T): T => {
    if (!input.response) {
        throw new Error("No response");
    }
    if (!input.response.ok) {
        throw input.response;
    }
    return input;
};
