import Link from "next/link";
import React from "react";

export const CertificationQuizFailure = () => {
    return (
        <>
            <div className="mb-6">
                <div className="text-medium font-bold text-secondaryDark flex flex-col gap-4">
                    <p>
                        Unfortunately, your score wasn’t high enough to pass the
                        Certified quiz.
                    </p>
                    <p>
                        We suggest you review the Certified curriculum here and
                        take the quiz again. You may take the quiz as many times
                        as you need to achieve a passing score.
                    </p>
                    <p>
                        To earn certification, advisors must pass the Certified
                        quiz and make their first booking, which will be logged
                        in Portal. Once complete, we issue certification at the
                        beginning of each month.
                    </p>
                </div>
            </div>
            <div className={"flex items-center justify-end gap-6"}>
                <Link
                    href="/training/certified"
                    className="btn-primary py-3 px-6 w-full md:w-auto min-w-[86px]"
                >
                    Return to Training
                </Link>
            </div>
        </>
    );
};
