import useSWR from "swr";
import { ComponentProps } from "react";
import { Typedown } from "../Typedown";
import Loader from "components/common/Loader";

type Props = ComponentProps<typeof Typedown> & {
    url: string;
    parseResult: (data: any) => { id: string; name: string }[];
};

export const DataboundTypedown = ({
    url,
    parseResult = (data: any) =>
        (data?.results || []).map(
            ({ id, name }: { id: string; name: string }) => ({
                id,
                name
            })
        ),
    ...otherProps
}: Props) => {
    const { data, isLoading, error } = useSWR(url);
    const options = parseResult(data);
    if (error) {
        return "Error";
    }
    return (
        <div className="relative">
            {isLoading && (
                <div className="absolute top-8 md:top-10 left-4 z-10">
                    <Loader />
                </div>
            )}
            <Typedown {...otherProps} options={options} />
        </div>
    );
};
