import React from "react";
import { SimpleModal } from "./SimpleModal";
import { RequestSupplierLoginForm } from "components/form/forms/RequestSupplierLoginForm";
import { useSearchParams } from "next/navigation";

export const RequestSupplierLoginModal = () => {
    const searchParams = useSearchParams();
    const supplier = searchParams.get("supplier");
    const stage = searchParams.get("stage");
    const defaultValues = {
        ...(supplier ? { supplier } : {}),
        ...(stage ? { stage } : {})
    };
    return (
        <SimpleModal title="Request a Supplier Login">
            {({ closeModal }) => (
                <RequestSupplierLoginForm
                    onDone={closeModal}
                    defaultValues={defaultValues}
                />
            )}
        </SimpleModal>
    );
};
