import uiConfig from "ui-static-data.json";

export const getStaticGoogleLink = (urls: string[] = []): string => {
    // @ts-ignore
    const parsedUrl: string = uiConfig[urls[0]][urls[1]] || "";

    const searchParams = new URL(parsedUrl);

    const queryDivider = [...searchParams.searchParams.keys()].length
        ? "&"
        : "?";

    return `${parsedUrl}${queryDivider}authuser=`;
};

export const getApiGoogleLink = (url: string = "") => {
    const urlPatterns = ["docs.google.com", "drive.google.com"];
    let parsedUrl: any = url;

    if (urlPatterns.some(pattern => url.includes(pattern))) {
        parsedUrl = `${url}authuser=`;
    }

    return parsedUrl;
};
