import React from "react";
import { SimpleModal } from "./SimpleModal";
import { useSearchParams } from "next/navigation";
import { CertificationQuizResult } from "components/training/certification-quiz-result/CertificationQuizResult";

export const CertificationQuizResultModal = () => {
    const searchParams = useSearchParams();
    const certificationQuizResult = searchParams.get(
        "certification_quiz_result"
    );
    const success = certificationQuizResult === "success";
    return (
        <SimpleModal
            title={
                success
                    ? "Congrats! You passed the Certified Quiz!"
                    : "Study time!"
            }
        >
            {({ closeModal }) => (
                <CertificationQuizResult
                    success={success}
                    closeModal={closeModal}
                />
            )}
        </SimpleModal>
    );
};
