export const pageview = (url, id) => {
    if (typeof window.gtag !== 'undefined') {
        window.gtag("config", id, {
            page_path: url
        });
    }
};
export const event = ({ action, category, label, value }) => {
    if (typeof window.gtag !== 'undefined') {
        window.gtag("event", action, {
            event_category: category,
            event_label: label,
            value: value
        });
    }
};
