import React, { useState, createContext } from "react";
import ModalCore from "./ModalCore";

interface ModalProviderProps {
    children: React.ReactNode;
}

const ModalContext: React.Context<ModalContextType> =
    createContext<ModalContextType>({
        isModalActive: false,
        setModalActiveState: () => {},
        modalContent: <></>,
        setModalComponent: () => {}
    });

const { Provider } = ModalContext;

const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isModalActive, setIsModalActive] = useState(false);
    const [modalContent, setModalContent] = useState<React.ReactNode | null>();

    const setModalActiveState = (isActive: boolean) => {
        setIsModalActive(isActive);
    };

    const setModalComponent = (component: React.ReactNode) => {
        setModalContent(component);
    };
    return (
        <Provider
            value={{
                isModalActive,
                setModalActiveState,
                modalContent,
                setModalComponent
            }}
        >
            <ModalCore isOpen={isModalActive} setIsOpen={setModalActiveState}>
                {modalContent}
            </ModalCore>

            {children}
        </Provider>
    );
};

export { ModalContext, ModalProvider };
