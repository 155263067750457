import { Dropdown } from "components/common/v2/Dropdown";
import { Typedown } from "components/common/v2/Typedown";
import { useFormikContext } from "formik";
import { SubmitScreen } from "components/form/pages/SubmitScreen";
import { Textarea } from "../common/v2/Textarea";
import { Input } from "../common/v2/Input";
import { FormProperties, PageSchema } from "types/form/schema";
import { twMerge } from "tailwind-merge";
import { DataboundTypedown } from "components/common/v2/widgets/DataboundTypedown";
import getConfig from "next/config";

const Pages: { [key: string]: (props: any) => JSX.Element } = {
    SubmitScreen
};

const FieldComponents: { [key: string]: (props: any) => any } = {
    DataboundTypedown
};

export const Page = ({
    schema,
    properties,
    submittedFirstTime
}: {
    schema: PageSchema;
    properties: FormProperties;
    submittedFirstTime: boolean;
}) => {
    const { publicRuntimeConfig } = getConfig();
    const { values, errors, setFieldValue, touched } = useFormikContext<{
        [key: string]: number | string;
    }>();

    if (schema?.component && Pages[schema.component]) {
        const PageComponent = Pages[schema.component];
        return <PageComponent schema={schema} properties={properties} />;
    }

    return (
        <div className="flex flex-wrap gap-x-4">
            {schema.properties?.map((propertyId: string) => {
                const value: string | number | undefined = values[propertyId];
                const currentProperty = properties[propertyId];
                const styleClass = twMerge(
                    "mb-6 grow w-full",
                    currentProperty.className
                );
                const updateValue = (value?: string | number | undefined) => {
                    setFieldValue(propertyId, value, true);
                };
                const error =
                    touched[propertyId] || submittedFirstTime
                        ? errors[propertyId]
                        : undefined;
                if (
                    currentProperty.oneOf ||
                    currentProperty.component === "Typedown"
                ) {
                    const MultipleChoiceComponent =
                        currentProperty.component === "Typedown"
                            ? Typedown
                            : Dropdown;

                    return (
                        <div className={styleClass} key={propertyId}>
                            <MultipleChoiceComponent
                                id={propertyId}
                                options={currentProperty.oneOf || []}
                                label={currentProperty.title}
                                placeholder={currentProperty.placeholder}
                                value={value}
                                onChange={updateValue}
                                error={error}
                                data-testid={propertyId}
                                required={currentProperty.required}
                                {...(currentProperty.component === "Typedown" &&
                                currentProperty.default
                                    ? {
                                          defaultSearchValue:
                                              currentProperty.default
                                      }
                                    : {})}
                            />
                        </div>
                    );
                }
                if (currentProperty.component === "Textarea") {
                    return (
                        <div className={styleClass} key={propertyId}>
                            <Textarea
                                id={propertyId}
                                label={currentProperty.title}
                                placeholder={currentProperty.placeholder}
                                value={value}
                                onChange={updateValue}
                                error={error}
                                required={currentProperty.required}
                            />
                        </div>
                    );
                }

                const FieldComponent =
                    currentProperty.component &&
                    FieldComponents[currentProperty.component]
                        ? FieldComponents[currentProperty.component]
                        : Input;

                return (
                    <div className={styleClass} key={propertyId}>
                        <FieldComponent
                            id={propertyId}
                            label={currentProperty.title}
                            placeholder={currentProperty.placeholder}
                            value={value}
                            onChange={updateValue}
                            error={error}
                            required={currentProperty.required}
                            {...currentProperty.componentProps}
                            {...(currentProperty.component ===
                            "DataboundTypedown"
                                ? {
                                      url: `${publicRuntimeConfig.nextBase}${
                                          currentProperty.componentProps?.url ||
                                          ""
                                      }`
                                  }
                                : {})}
                        />
                    </div>
                );
            })}
        </div>
    );
};
