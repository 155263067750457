import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { useRouter } from "next/router";

import getConfig from "next/config";
import { pageview } from "lib/gtag";
import { useAuth } from "hooks/use-auth";

type Props = {
    children: any;
    signupData?: {
        email: string;
        personalEmail: string;
    };
};

// Only holds serverRuntimeConfig and publicRuntimeConfig
const { publicRuntimeConfig } = getConfig();
const gaTrackingId = publicRuntimeConfig.gaTrackingId;
const hotjarID = publicRuntimeConfig.hotjarID;
const hotjarVS = publicRuntimeConfig.hotjarVS;

export default function HotjarTracking({ children, signupData }: Props) {
    const { userProfile } = useAuth();
    const router = useRouter();
    const isSignupPage =
        signupData?.email && signupData?.personalEmail;

    useEffect(() => {
        if (gaTrackingId) {
            const handleRouteChange = (url: string) => {
                pageview(url, gaTrackingId);
            };
            router.events.on("routeChangeComplete", handleRouteChange);

            return () => {
                router.events.off("routeChangeComplete", handleRouteChange);
            };
        }
    }, [router.events]);

    useEffect(() => {
        if (hotjarID && hotjarVS) {
            hotjar.initialize(Number(hotjarID), Number(hotjarVS));
        }
    }, []);

    useEffect(() => {
        if (userProfile && hotjarID && hotjarVS) {
            hotjar.identify(`${userProfile?.id}`, {
                email: userProfile.email,
                fullName: userProfile.firstName + " " + userProfile.lastName
            });
        }
    }, [userProfile]);

    useEffect(() => {
        if (isSignupPage && hotjarID && hotjarVS) {
            const { email, personalEmail } = signupData;
            const personalEmailCut = personalEmail.indexOf("@");
            hotjar.identify("", {
                email,
                personal_email_1: personalEmail.slice(0, personalEmailCut),
                personal_email_2: personalEmail.slice(personalEmailCut + 1)
            });
        }
    }, [isSignupPage]);

    return children;
}
