import { AccessibleFeatures } from "types/index";

export const checkFeatureEnabled = (
    accessibleFeatures: AccessibleFeatures,
    featureName: string,
    featureValueName?: string
): boolean => {
    const hasBaseFeature = Object.keys(accessibleFeatures).includes(featureName);
    if (hasBaseFeature && featureValueName) {
        return !!accessibleFeatures[featureName][featureValueName]
    }
    return hasBaseFeature;
};
