import { Component, PropsWithChildren } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import ErrorPage from "components/errors/ErrorPage";
import * as Sentry from "@sentry/nextjs";

interface State {
    hasError?: boolean;
}

interface Props extends PropsWithChildren<{}> {}

export class GlobalErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        datadogLogs.logger.error(
            `GlobalErrorBoundary: Error rendering, showing default template`,
            {
                error
            }
        );
        Sentry.captureException(error);
    }

    render() {
        const that = this;
        if (this.state.hasError) {
            return (
                <ErrorPage showBackButton={true}>
                    An unexpected error has occurred. Please refresh the page.
                    If the issue persists, email{" "}
                    <a href="mailto:support@fora.travel" className="text-link">
                        support@fora.travel
                    </a>
                </ErrorPage>
            );
        }

        return <>{this.props.children}</>;
    }
}
