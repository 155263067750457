import { X } from "phosphor-react";
import { ReactNode } from "react";

interface Props {
    title?: string | ReactNode;
    onDone?: () => void;
}
export const ModalHeader = ({ title, onDone }: Props) => {
    if (!title && !onDone) return null;
    return (
        <div
            className={`flex items-center  mb-4 ${
                onDone && !title ? "justify-end" : "justify-between"
            }`}
        >
            {title && (
                <h3 className="text-header font-bold text-left flex-1">
                    {title}
                </h3>
            )}
            {onDone && (
                <X
                    size={18}
                    className={" fill-main cursor-pointer"}
                    onClick={onDone}
                />
            )}
        </div>
    );
};
