import { twMerge } from "tailwind-merge";
interface Props extends React.ComponentPropsWithoutRef<"label"> {
    required?: boolean;
}

export const Label = ({
    children,
    className,
    required = false,
    ...otherProps
}: Props) => {
    if (!children) return null;
    return (
        <label
            {...otherProps}
            className={twMerge(
                `text-medium text-secondaryDark md:mb-2 block`,
                className
            )}
        >
            {children}
            {required && "*"}
        </label>
    );
};
