import React from "react";
import { CertificationQuizSuccess } from "./CertificationQuizSuccess";
import { CertificationQuizFailure } from "./CertificationQuizFailure";

export const CertificationQuizResult = ({
    success,
    closeModal
}: {
    success: boolean;
    closeModal: () => void;
}) => {
    if (!success) return <CertificationQuizFailure />;
    return <CertificationQuizSuccess closeModal={closeModal} />;
};
