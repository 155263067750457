import { twMerge } from "tailwind-merge";
import { InputError } from "./InputError";
import { Label } from "components/common/v2/Label";
import { Icon as PhosphorIconT } from "phosphor-react";

export const baseInputStyles =
    "shadow-0 text-medium focus:ring-0 focus:border-main block w-full border-stroke rounded-xl py-[11px] px-4 placeholder:text-main/50 form-control wrapper-test-field";

export interface InputProps
    extends Omit<React.ComponentPropsWithoutRef<"input">, "onChange"> {
    /** A label for the input */
    label?: string;
    /** An error message to display in red below the input */
    error?: string;
    onChange: (value: string) => void;
    /** If true, adds a * to the right of the label text */
    required?: boolean;
    /** Styles to apply to the div around the component */
    containerClassName?: string;
    /** An icon to display to the left of the text-input area */
    PhosphorIcon?: PhosphorIconT;
}

/** A input box with an optional label, icon, and error message */
export const Input = ({
    onChange,
    label,
    error,
    id,
    className,
    required,
    containerClassName,
    PhosphorIcon,
    ...otherProps
}: InputProps) => {
    const input = (
        <input
            {...otherProps}
            id={id}
            className={twMerge(
                baseInputStyles,
                error && "border-error focus:border-error",
                PhosphorIcon && "pl-[38px]",
                className
            )}
            onChange={e => onChange(e.target.value)}
        />
    );

    return (
        <div className={containerClassName}>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            {!PhosphorIcon ? (
                input
            ) : (
                <div className="relative">
                    {input}
                    <PhosphorIcon
                        size={18}
                        className={`absolute left-[13px] top-[15px] ${
                            otherProps.value ? "text-main" : "text-tertiaryOld"
                        }`}
                    />
                </div>
            )}
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
};
